import {AsyncRequestStatus} from "../../enums/AsyncRequestStatus";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import LoginRequest from "./models/LoginRequest";
import {postLogin} from "./authAPI";
import {RootState} from "../../app/store";

export interface AuthState {
    status: AsyncRequestStatus,
    token?: string,
    email: string,
    firstName: string,
    lastName: string
}

const initialState: AuthState = {
    status: AsyncRequestStatus.Idle
} as AuthState;

export const loginAction = createAsyncThunk(
    'auth/login',
    async (data: LoginRequest) => {
        return (await postLogin(data)).data;
    }
)

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            state.token = undefined;
        },
        resetRedirect: (state) => {
            state.status = AsyncRequestStatus.Idle;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginAction.pending, (state) => {
                state.status = AsyncRequestStatus.Loading;
            })
            .addCase(loginAction.fulfilled, (state, action) => {
                console.log(action.payload);
                state.status = AsyncRequestStatus.Redirect;
                state.token = action.payload.token;
                state.email = action.payload.email;
                state.firstName = action.payload.firstName;
                state.lastName = action.payload.lastName;
            })
            .addCase(loginAction.rejected, (state) => {
                state.status = AsyncRequestStatus.Failed;
            })
    }
})

export const { logout, resetRedirect } = authSlice.actions;

export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer;