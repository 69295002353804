import {MapContainer, Marker, Popup, TileLayer, useMap} from "react-leaflet";
import React, {useEffect, useMemo, useRef} from "react";
import {LatLng} from "leaflet";
import {SellerPointOfInterestIcon} from "./Markers";

type SmallMapProps = {
    position: LatLng,
    setCoordinates?: any, // TODO FIX
    description: string,
    draggable: boolean
}

const SmallMap = (props: SmallMapProps) => {
    const markerRef = useRef(null);
    const eventHandlers = useMemo(
        () => ({
            dragend() {
                // TODO Thei: fix any
                const marker: any = markerRef.current;
                if (marker != null) {
                    let position = marker.getLatLng();
                    props.setCoordinates(position.lat, position.lng);
                    
                }
            },
        }),
        [],
    );

    const mapRef = useRef<any>(null)
    
    const setMap = ( map: any ) => {
        console.info("THEI");
            if (map != null) {
                console.log("INVALIDATE SIZE");
                map.invalidateSize();
            } else {
                console.log("MEH");
            }
    }

    const resizeMap = (mapRef: any) => {
        const resizeObserver = new ResizeObserver(() => mapRef.current?.invalidateSize())
        const container = document.getElementById('map-container')
        if (container) {
            resizeObserver.observe(container);
        }
    }
    
    return (<div style={{width: '100%', height: '400px'}}>
        <MapContainer ref={mapRef} whenReady={() => resizeMap(mapRef)} id="map-container" style={{width:'100%', height: '400px'}} center={props.position} zoom={24} scrollWheelZoom={false}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker
                icon={SellerPointOfInterestIcon}
                ref={markerRef}
                eventHandlers={eventHandlers}
                position={props.position}
                draggable={props.draggable}
            >
                <Popup>
                    <div dangerouslySetInnerHTML={{ __html: props.description }}></div>
                </Popup>
            </Marker>
        </MapContainer>
    </div>);
}

export default SmallMap;