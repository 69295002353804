import axios from "axios";
import config from "./config.json";
import {store} from "./store";

const createClient = () => {
    let client = axios;
    client.defaults.baseURL = config.baseURL;
    
    if (store.getState().auth.token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${(store.getState().auth.token)}`;
    }
    return client;
}

// const client = createClient();

export default createClient;