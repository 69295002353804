import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import {persistor, store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import {ConfigProvider} from "antd";
import nl_NL from 'antd/locale/nl_NL';
import theme from "./theme";
import { PersistGate } from 'redux-persist/integration/react';
import {MantineProvider} from "@mantine/core";
import {ModalsProvider} from "@mantine/modals";

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ConfigProvider theme={theme} locale={nl_NL}>
                    <MantineProvider>
                        <ModalsProvider>
                            <App />
                        </ModalsProvider>
                    </MantineProvider>
                </ConfigProvider>
            </PersistGate>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
