import {Badge, Stack, Text} from "@mantine/core";
import {IconBan, IconBolt, IconCheck} from "@tabler/icons-react";
import React from "react";
import {ColumnProps} from "./ColumnProps";
import {format, parseISO} from "date-fns";
import {nl} from "date-fns/locale";
import {GarageSaleSellerStatus} from "../../../../features/garagesale/enums/GarageSaleSellerStatus";

interface StatusBadgeProps {
    status: GarageSaleSellerStatus
}

const badgeDetails = [
    {
        color: "violet",
        icon: <IconBolt size="0.8rem" />,
        value: "Nieuw"
    },
    {
        color: "red",
        icon: <IconBan size="0.8rem" />,
        value: "Afgewezen"
    },
    {
        color: "green",
        icon: <IconCheck size="0.8rem" />,
        value: "Bevestigd"
    },
];

const StatusBadge = (props: StatusBadgeProps) => {
    let details = badgeDetails[props.status]

    return (
        <Badge variant="filled" size="sm" color={details.color} radius="md" leftSection={details.icon}>
            {details.value}
        </Badge>);
}

export const NameColumn = (props: ColumnProps) => {
    const date = parseISO(props.seller.createdAt);
    let createdAt =<time dateTime={props.seller.createdAt}>{format(date, "d MMMM yyyy 'om' H:mm", {locale: nl})}</time>;
    
    return (
        <>
            <Stack align="left" justify="space-between">
                <div>
                    <strong>{props.seller.firstName} {props.seller.lastName}</strong> ({props.seller.displayName})<br />
                    <Text color="dimmed">Aangemeld op {createdAt}</Text>
                </div>
            <div>
                <StatusBadge status={props.seller.status} />
            </div>
            </Stack>
        </>
    );
}