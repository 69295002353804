import createClient from "../../app/client";
import axios from "axios";

export function fetchGarageSale(slug: string) {
    return createClient().get(`garagesale/${slug}`);
}

export interface DeleteSellerRequest {
    slug: string,
    id: string
}

export function deleteSeller(request: DeleteSellerRequest) {
    return createClient().delete(`garagesale/${request.slug}/seller/${request.id}`);
}

export interface ConfirmSellerRequest {
    slug: string,
    id: string
}

export function confirmSeller(request: ConfirmSellerRequest) {
    return createClient().post(`garagesale/${request.slug}/seller/${request.id}/confirm`);
}

export interface UpdateSellerRequest {
    slug: string,
    id: string,
    displayName: string,
    firstName: string,
    lastName: string,
    street: string,
    houseNumber: string,
    postcode: string,
    city: string,
    country: string,
    phone: string,
    email: string,
    atHome: boolean,
}

export function updateSeller(request: UpdateSellerRequest) {
    return createClient().patch(`garagesale/${request.slug}/seller/${request.id}`, request);
}

export function exportSellers(slug: string) {
    axios({
        url: `garagesale/${slug}/exportsellers`,
        method: "GET",
        responseType: 'blob'
    }).then(response => {
        const href = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = href;
        console.log(response.headers);
        link.setAttribute('download', `export-${slug}-${Date.now()}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }).catch(() => {
        alert("Er is een onverwachte fout opgetreden");
    });
}