
import { RootState } from '../../app/store';
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface ToastMessage {
  message: string,
  type: 'success' | 'warning' | 'error' | 'info'
}

export interface ToastState {
  message: ToastMessage | null;
}

const initialState: ToastState = {
  message: null
};

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetMessage: (state) => {
      state.message = null;
    },
    setMessage: (state, action: PayloadAction<ToastMessage>) => {
      state.message = action.payload;
    },
  },
});

export const { resetMessage, setMessage } = toastSlice.actions;

export const selectToastMessage = (state: RootState) => state.toast.message;


export default toastSlice.reducer;
