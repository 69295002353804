import React from 'react';
import {
    createBrowserRouter, Outlet,
    RouterProvider,
} from 'react-router-dom';
import GarageSaleWrapper from "../pages/garagesale/GarageSaleWrapper";
import Login from "../pages/login/Login";
import GarageSaleHome from "../pages/garagesale/GarageSaleHome";
import Home from "../pages/home/Home";


const router = createBrowserRouter([
    {
        path: "/",
        element: <Outlet />,
        children: [
            {
                path: "",
                element: <Home />,
            },
            {
                path: "login",
                element: <Login />,
            },
            {
                path: "/garagesale/:slug/",
                element: <GarageSaleWrapper />,
                children: [
                    // {
                    //     path: "aanmelden",
                    //     element: <RouteSignup />,
                    // },
                    {
                        path: "",
                        element: <GarageSaleHome />,
                    },
                ]
            },
        ]
    },
    
]);

const RouteManagerRouter = () => {
    return <RouterProvider router={router} />; 
}

export default RouteManagerRouter;