import './App.css';
import RouteManagerRouter from "./app/RouteManagerRouter";
import { notification } from 'antd';
import {selectGarageSale, updateGarageSaleAction} from "./features/garagesale/garagesaleSlice";
import {AsyncRequestStatus} from "./enums/AsyncRequestStatus";
import {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "./app/hooks";
import {resetMessage, selectToastMessage} from "./features/toast/toastSlice";
// import {selectRoute} from "./features/route/routeSlice";
// import {selectSignup} from "./features/signup/signupSlice";

function App() {
  const [api, contextHolder] = notification.useNotification();
    const garagesale = useAppSelector(selectGarageSale);
    const toast = useAppSelector(selectToastMessage);
    const dispatch = useAppDispatch();

    useEffect(() => {
      if (garagesale.status === AsyncRequestStatus.Failed) {
        api.error({
          message: `Oeps, er ging iets mis ┗(⊙_☉)┘`,
          description: <div>Probeer het later nogmaals of neem contact op met de helpdesk via <a href="mailto:helpdesk@buurtroute.nl">helpdesk@buurtroute.nl</a>.</div>,
          placement: 'top',
          duration: 99999
        });
      } else if (garagesale.status === AsyncRequestStatus.Refresh) {
        dispatch(updateGarageSaleAction(garagesale.garageSale!.slug));
      } else if (toast !== null) {
        api.open({
          message: 'Ongeldige invoer',
          description: toast.message,
          type: toast.type,
          placement: 'top',
        });
        dispatch(resetMessage());
      }
    }, [garagesale, toast]);

  return (
      <div className="App">
        {contextHolder}
        <RouteManagerRouter />
      </div>
  );
}
export default App;