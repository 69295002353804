import {Outlet, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {fetchGarageSaleAction} from "../../features/garagesale/garagesaleSlice";
import {useEffect} from "react";
import {selectAuth} from "../../features/auth/authSlice";

const GarageSaleWrapper = () => {
    const { slug } = useParams<string>();
    const auth = useAppSelector(selectAuth);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (auth.token) {
            dispatch(fetchGarageSaleAction(slug as string));
        }
    }, [slug, dispatch, auth]);
    
    return (
        <>
            <Outlet />
        </>
    );
};

export default GarageSaleWrapper;