import Layout from "../../components/layout/Layout";
import React from "react";
import {useAppSelector} from "../../app/hooks";
import {selectGarageSale} from "../../features/garagesale/garagesaleSlice";
import {AsyncRequestStatus} from "../../enums/AsyncRequestStatus";
import ErrorComponent from "../../components/error/ErrorComponent";
import SellersTable from "./sellerstable/SellersTable";
import EditSellerModal from "./sellerstable/modals/EditSellerModal";
import { Button } from "@mantine/core";
import { Skeleton } from "antd";
import {IconFileSpreadsheet} from "@tabler/icons-react";
import {exportSellers} from "../../features/garagesale/garagesaleAPI";

const GarageSaleHome = () => {
    const garagesale = useAppSelector(selectGarageSale);

    return (<>
        <Layout>
            <div className="content">
                <div>
                    <Skeleton loading={garagesale.status === AsyncRequestStatus.Loading} active paragraph={{ rows: 10}} title={false} avatar={false} >
                        {garagesale.garageSale !== null && <>
                        <div className='route-title'>
                            <h1>{garagesale.garageSale.name}</h1>
                            <h2>{garagesale.garageSale.sellers?.length} inschrijvingen</h2>
                        </div>
                        <div>
                            <EditSellerModal />
                            <SellersTable sellers={garagesale.garageSale.sellers} />
                        </div>
                        <div>
                            <Button size="xs" leftIcon={<IconFileSpreadsheet size="0.9rem" />} onClick={() => exportSellers(garagesale.garageSale?.slug ?? "")}>
                                Exporteer naar CSV
                            </Button>
                        </div>
                        </>}

                        {garagesale.garageSale === null && <>
                            <ErrorComponent message="Deze route kan niet getoond worden" />
                        </>}
                    </Skeleton>
                </div>
            </div>
        </Layout>
        </>);
};

export default GarageSaleHome;