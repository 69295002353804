import {ColumnProps} from "./ColumnProps";

export const ContactInformationColumn = (props: ColumnProps) => {
    return (
        <>
            {props.seller.street} {props.seller.houseNumber}<br />
            {props.seller.postcode} {props.seller.city}<br />
            {props.seller.email}<br />
            {props.seller.phone}
            
        </>
    );
}