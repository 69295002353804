import GarageSaleSeller from "../../../features/garagesale/models/GarageSaleSeller";
import React, {useEffect, useState} from "react";
import {DataTable} from "mantine-datatable";
import {NameColumn} from "./columns/NameColumn";
import {ContactInformationColumn} from "./columns/ContactInformationColumn";
import ActionsColumn from "./columns/ActionsColumn";
import {MultiSelect, Select, TextInput} from "@mantine/core";
import {useDebouncedValue} from "@mantine/hooks";
import {IconSearch} from "@tabler/icons-react";

interface SellerTableProps {
    sellers: GarageSaleSeller[]
}

const PAGE_SIZE = 10;

const SellersTable = (props: SellerTableProps) => {
    const [sellers, setSellers] = useState(props.sellers);
    const [page, setPage] = useState(1);
    const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
    const [searchNameQuery, setSearchNameQuery] = useState('');
    const [searchContactQuery, setSearchContactQuery] = useState('');
    const [debouncedSearchNameQuery] = useDebouncedValue(searchNameQuery, 200);
    const [debouncedSearchContactQuery] = useDebouncedValue(searchContactQuery, 200);
    
    const statuses = ['Nieuw', 'Afgewezen', 'Bevestigd'];

    const sortByCreationDate = (a: GarageSaleSeller, b: GarageSaleSeller) =>
    {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    }
    
    useEffect(() => {

        const from = (page - 1) * PAGE_SIZE;
        const to = from + PAGE_SIZE;
        setSellers(
            props.sellers.filter((seller) => {
                if (
                    debouncedSearchNameQuery !== '' &&
                    !`${seller.firstName} ${seller.lastName}`.toLowerCase().includes(debouncedSearchNameQuery.trim().toLowerCase())
                ) {
                    return false;
                }
                
                if (
                    debouncedSearchContactQuery !== '' &&
                    !`${seller.street} ${seller.houseNumber} ${seller.postcode} ${seller.city} ${seller.email} ${seller.phone}`.toLowerCase().includes(debouncedSearchContactQuery.trim().toLowerCase())
                ) {
                    return false;
                }
                
                if (selectedStatuses.length > 0 && !selectedStatuses.some((d) => statuses.indexOf(d).toString() === seller.status.toString())) {
                    return false;
                }
                return true;
                
            }).sort(sortByCreationDate).slice(from, to)
        );
    }, [props.sellers, selectedStatuses, debouncedSearchNameQuery, debouncedSearchContactQuery, page]);
    
    
    if (props.sellers == null)
        return <></>;

    const columns: any = [
        {
            accessor: 'name',
            title: 'Deelnemer',
            textAlignment: 'left',
            filter: (<>
                <TextInput
                    label="Naam"
                    description="Zoek op naam"
                    placeholder="Zoek deelnemers..."
                    icon={<IconSearch size={16} />}
                    value={searchNameQuery}
                    onChange={(e) => setSearchNameQuery(e.currentTarget.value)}
                />
                <MultiSelect
                    label="Status"
                    description="Toon alleen deelnemers met de volgende status"
                    value={selectedStatuses}
                    data={statuses}
                    placeholder="Kies een status..."
                    onChange={setSelectedStatuses}
                    icon={<IconSearch size={16} />}
                    clearable
                    searchable
                />
            </>),
            filtering: selectedStatuses.length > 0 || searchNameQuery !== '',
            render: (seller: GarageSaleSeller) => <NameColumn seller={seller} />
        },
        {
            accessor: 'contactinformation',
            title: 'Contactinformatie',
            textAlignment: 'left',
            filter: (<>
                <TextInput
                    label="Contactinformatie"
                    description="Zoek op adres, email, of telefoonnummer"
                    placeholder="Zoek deelnemers..."
                    icon={<IconSearch size={16} />}
                    value={searchContactQuery}
                    onChange={(e) => setSearchContactQuery(e.currentTarget.value)}
                />
            </>),
            filtering: selectedStatuses.length > 0 || searchNameQuery !== '',
            render: (seller: GarageSaleSeller) => <ContactInformationColumn seller={seller} />
        },
        {
            accessor: 'actions',
            title: 'Acties',
            textAlignment: 'right',
            render: (seller: GarageSaleSeller) => <ActionsColumn seller={seller} />
        },
        
    ];
    
    return (
        <>
            <DataTable
                totalRecords={props.sellers.length}
                recordsPerPage={PAGE_SIZE}
                page={page}
                onPageChange={(p) => setPage(p)}
                minHeight={400}
                noRecordsText="Geen resultaten"
                striped
                highlightOnHover
                style={{width: '100%'}}
                records={sellers}
                columns={columns}
            />
        </>
    );
}

export default SellersTable;