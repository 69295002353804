import React from "react";
import Layout from "../../components/layout/Layout";
import {useAppDispatch} from "../../app/hooks";
import {loginAction} from "../../features/auth/authSlice";
import {Button, Form, Input} from "antd";

const Login = () => {
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();

    const onFinish = () => {
        let data = form.getFieldsValue();
        dispatch(loginAction(data));
    }

    const onFinishFailed = () => {
        
    }
    return (
        <Layout overrideAuthStatus={true}>
            <div className="container-narrow">
                <div className="content">
                    <div>
                        <Form
                            form={form}
                            name="basic"
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 18 }}
                            initialValues={{
                                // email: 'thei@buurtroute.nl',
                                // password: 'password'
                            }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item
                                label="Emailadres"
                                name="email"
                                rules={[{ required: true }]}
                            >
                                <Input />
                            </Form.Item>
    
                            <Form.Item
                                label="Wachtwoord"
                                name="password"
                                
                                rules={[{ required: true }]}
                            >
                                <Input
                                    type="password"
                                />
                            </Form.Item>
    
                            <div style={{display: 'flex', justifyContent: 'end'}}>
                            <Button
                                type="primary"
                                htmlType="submit">
                                Inloggen
                            </Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Login;