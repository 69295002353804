import {Icon, Point} from "leaflet";

const pointOfInterestIcon = (iconUrl: string) => new Icon({
    iconSize: new Point(30, 30),
    popupAnchor: new Point(0, -10),
    iconUrl: iconUrl,
});

export const SellerPointOfInterestIcon = pointOfInterestIcon('/img/icon-poi-seller.png');
export const CoffeePointOfInterestIcon = pointOfInterestIcon('/img/icon-poi-coffee.png');
export const StarPointOfInterestIcon = pointOfInterestIcon('/img/icon-poi-star.png');