import {Box, Button, Grid, Group, LoadingOverlay, Modal, Radio, Tabs, TextInput, useMantineTheme} from "@mantine/core";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {resetSelectedSeller, selectGarageSale, updateSellerAction} from "../../../../features/garagesale/garagesaleSlice";
import {useMediaQuery} from "@mantine/hooks";
import {AsyncRequestStatus} from "../../../../enums/AsyncRequestStatus";
import {IconMap2, IconUser} from "@tabler/icons-react";
import GarageSaleSeller from "../../../../features/garagesale/models/GarageSaleSeller";
import {isEmail, isNotEmpty, useForm} from "@mantine/form";
import React, {useEffect, useState} from "react";
import {UpdateSellerRequest} from "../../../../features/garagesale/garagesaleAPI";
import {setMessage} from "../../../../features/toast/toastSlice";
import SmallMap from "../../../../components/map/SmallMap";
import { LatLng } from "leaflet";

const EditSellerPanels = () => {
    const dispatch = useAppDispatch();
    const garageSale = useAppSelector(selectGarageSale);
    const [atHome, setAtHome] = useState<boolean>(false);
    const form = useForm({
        initialValues: {
            displayName: '',
            firstName: '',
            lastName: '',
            street: '',
            houseNumber: '',
            postcode: '',
            city: '',
            email: '',
            phone: ''
        },
        validate: {
            displayName: isNotEmpty('Vul een voornaam in'),
        },
    });
    
    useEffect(() => {

        if (garageSale.selectedSeller === null)
            return;

        form.setValues({
            displayName: garageSale.selectedSeller.displayName,
            firstName: garageSale.selectedSeller.firstName,
            lastName: garageSale.selectedSeller.lastName,
            street: garageSale.selectedSeller.street,
            houseNumber: garageSale.selectedSeller.houseNumber,
            postcode: garageSale.selectedSeller.postcode,
            city: garageSale.selectedSeller.city,
            email: garageSale.selectedSeller.email,
            phone: garageSale.selectedSeller.phone
        });
        setAtHome(garageSale.selectedSeller.atHome);

    }, [garageSale]);

    const submitUpdatePersoonsgegevens = () => {
        if (!form.isValid()) {
            dispatch(setMessage({message: 'Niet alle velden zijn juist ingevuld', type: 'error'}));
            return;
        }
            
        let request = {
            slug: garageSale.garageSale?.slug,
            id: garageSale.selectedSeller?.id,
            country: "Nederland", // TODO: Tijdelijk hardcoded
            ...form.values,
            atHome: atHome
        } as UpdateSellerRequest;
        dispatch(updateSellerAction(request));
    };

    const submitUpdatePosition = () => {
        // TODO: handle update
    }

    return (<>
        <Tabs defaultValue="personal-information">
            <Tabs.List grow>
                <Tabs.Tab value="personal-information" icon={<IconUser size="0.8rem" />}>Persoonsgegevens</Tabs.Tab>
                <Tabs.Tab value="map" icon={<IconMap2 size="0.8rem" />}>Kaart</Tabs.Tab>
            </Tabs.List>
            
            <Tabs.Panel value="personal-information" pt="xs" mih={400}>
                <Grid>
                    <Grid.Col span={12}>
                        <TextInput
                            placeholder="Weergavenaam"
                            label="Weergavenaam"
                            withAsterisk
                            data-autofocus
                            {...form.getInputProps('displayName')}
                        />
                    </Grid.Col>
                    <Grid.Col md={12} lg={6}>
                        <TextInput
                            placeholder="Voornaam"
                            label="Voornaam"
                            withAsterisk
                            {...form.getInputProps('firstName')}
                        />
                    </Grid.Col>
                    <Grid.Col md={12} lg={6}>
                        <TextInput
                            placeholder="Achternaam"
                            label="Achternaam"
                            withAsterisk
                            {...form.getInputProps('lastName')}
                        />
                    </Grid.Col>
                    <Grid.Col md={12} lg={8}>
                        <TextInput
                            placeholder="Straat"
                            label="Straat"
                            withAsterisk
                            {...form.getInputProps('street')}
                        />
                    </Grid.Col>
                    <Grid.Col md={12} lg={4}>
                        <TextInput
                            placeholder="Huisnummer"
                            label="Huisnummer"
                            withAsterisk
                            {...form.getInputProps('houseNumber')}
                        />
                    </Grid.Col>
                    <Grid.Col md={12} lg={6}>
                        <TextInput
                            placeholder="Email"
                            label="Email"
                            withAsterisk
                            {...form.getInputProps('email')}
                        />
                    </Grid.Col>
                    <Grid.Col md={12} lg={6}>
                        <TextInput
                            placeholder="Telefoon"
                            label="Telefoon"
                            withAsterisk
                            {...form.getInputProps('phone')}
                        />
                    </Grid.Col>
                    <Grid.Col md={12} lg={4}>
                        <TextInput
                            placeholder="Postcode"
                            label="Postcode"
                            withAsterisk
                            {...form.getInputProps('postcode')}
                        />
                    </Grid.Col>
                    <Grid.Col md={12} lg={8}>
                        <TextInput
                            placeholder="Stad"
                            label="Stad"
                            withAsterisk
                            {...form.getInputProps('city')}
                        />
                    </Grid.Col>

                    {/*{garageSale.garageSale?.mainLocationName &&*/}
                    <Grid.Col span={12}>
                        <Radio.Group
                            value={atHome ? "true" : "false"}
                            onChange={(e) => setAtHome(e ==="true")}
                            name="favoriteFramework"
                            label="Waar staat deze deelnemer?"
                            withAsterisk
                        >
                            <Group mt="xs">
                                <Radio value="true" label="Thuis" />
                                <Radio value="false" label="Centrale plek" />
                            </Group>
                        </Radio.Group>
                    </Grid.Col>
                </Grid>
                <ModalButtons onSubmit={submitUpdatePersoonsgegevens} submitText="Persoonsgegevens opslaan" />
            </Tabs.Panel>
            
            <Tabs.Panel value="map" pt="xs" mih={400}>
                <Grid>
                    <Grid.Col>
                        <SmallMap
                            position={new LatLng(garageSale.selectedSeller?.lat ?? 0, garageSale.selectedSeller?.lng ?? 0)}
                            description={garageSale.selectedSeller?.displayName ?? ""}
                            draggable={false}
                        />
                    </Grid.Col>
                </Grid>
                {/*<ModalButtons onSubmit={submitUpdatePosition} submitText="Nieuwe locatie opslaan" />*/}
            </Tabs.Panel>
        </Tabs>
    </>);
}

interface ModalButtonsProps {
    onSubmit: () => void,
    submitText?: string
}

const ModalButtons = (props: ModalButtonsProps) => {
    const dispatch = useAppDispatch();

    return (
        <>
            <Grid>
                <Grid.Col>
                    <Group position="right">
                        <Button
                            variant="default"
                            onClick={() => dispatch(resetSelectedSeller())}
                        >
                            Annuleren
                        </Button>
                        <Button
                            onClick={() => props.onSubmit()}
                        >
                            {props.submitText !== undefined ? props.submitText : "Opslaan"}
                        </Button>
                    </Group>
                </Grid.Col>
            </Grid>
        </>
    );
}

const EditSellerModal = () => {
    const {selectedSeller, updateSellerStatus} = useAppSelector(selectGarageSale);
    const isMobile = useMediaQuery("(max-width: 50em)");
    const theme = useMantineTheme();
    const dispatch = useAppDispatch();

    const overlayProps = {
        color: theme.colors.gray[2],
        opacity: 0.55,
        blur: 3,
    }
    
    const formatModalTitle = (seller: GarageSaleSeller | null) =>
        seller === null ? "" : `Deelnemer ${seller.firstName} ${seller.lastName} bewerken`

    return (
        <>
            <Modal
                size="xl"
                fullScreen={isMobile}
                withOverlay
                overlayProps={overlayProps}
                opened={selectedSeller !== null}
                onClose={() => dispatch(resetSelectedSeller())}
                title={formatModalTitle(selectedSeller)}
                centered
            >
                <Box pos="relative">
                    <LoadingOverlay visible={updateSellerStatus === AsyncRequestStatus.Loading} overlayBlur={2} />
                    <EditSellerPanels />
                </Box>
            </Modal>
        </>
    );
};

export default EditSellerModal;