import React, {PropsWithChildren} from "react";
import {Button, Result} from "antd";
import {useNavigate} from "react-router-dom";

type ErrorComponentProps = {
    message: string
}

const ErrorComponent = (props: PropsWithChildren<ErrorComponentProps>) => {
    const navigate = useNavigate();
    
    const goHome = () => navigate('/');
    
    return (
        <Result
            status="404"
            title={props.message}
            extra={
                <Button type="primary" onClick={goHome}>
                    Naar de beginpagina
                </Button>
            }
        />
    );
}

export default ErrorComponent;