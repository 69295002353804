import {AsyncRequestStatus} from "../../enums/AsyncRequestStatus";
import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    confirmSeller,
    ConfirmSellerRequest,
    deleteSeller,
    DeleteSellerRequest,
    fetchGarageSale,
    updateSeller, UpdateSellerRequest
} from "./garagesaleAPI";
import {RootState} from "../../app/store";
import GarageSale from "./models/GarageSale";
import GarageSaleSeller from "./models/GarageSaleSeller";

export interface GarageSaleState {
    status: AsyncRequestStatus,
    garageSale: GarageSale | null,
    updateSellerStatus: AsyncRequestStatus,
    selectedSeller: GarageSaleSeller | null
}

const initialState: GarageSaleState = {
    status: AsyncRequestStatus.Idle,
    garageSale: null,
    updateSellerStatus: AsyncRequestStatus.Idle,
    selectedSeller: null
} as GarageSaleState;

export const fetchGarageSaleAction = createAsyncThunk(
    'garagesale/fetch',
    async (slug: string) => {
        return (await fetchGarageSale(slug)).data;
    }
);

export const updateGarageSaleAction = createAsyncThunk(
    'garagesale/update',
    async (slug: string) => {
        return (await fetchGarageSale(slug)).data;
    }
);

export const confirmSellerAction = createAsyncThunk(
    'garagesale/confirmSeller',
    async (request: ConfirmSellerRequest) => {
        return (await confirmSeller(request)).data;
    }
);

export const deleteSellerAction = createAsyncThunk(
    'garagesale/deleteSeller',
    async (request: DeleteSellerRequest) => {
        return (await deleteSeller(request)).data;
    }
);

export const updateSellerAction = createAsyncThunk(
    'garagesale/updateSeller',
    async (request: UpdateSellerRequest) => {
        return (await updateSeller(request)).data;
    }
);

export const garageSaleSlice = createSlice({
    name: 'garagesale',
    initialState,
    reducers: {
        resetSelectedSeller: (state) => {
            state.selectedSeller = null;
        },
        setSelectedSeller: (state, action: PayloadAction<GarageSaleSeller>) => {
            state.selectedSeller = action.payload;
        },
        // tmpSetSellerLoading: (state) => {
        //     state.updateSellerStatus = AsyncRequestStatus.Loading;
        // },
        // tmpSetSellerIdle: (state) => {
        //     state.updateSellerStatus = AsyncRequestStatus.Idle;
        // }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchGarageSaleAction.pending, (state) => {
                state.status = AsyncRequestStatus.Loading;
            })
            .addCase(fetchGarageSaleAction.fulfilled, (state, action) => {
                state.status = AsyncRequestStatus.Idle;
                state.garageSale = action.payload.garageSale;
            })
            .addCase(fetchGarageSaleAction.rejected, (state) => {
                state.status = AsyncRequestStatus.Failed;
            })
            .addCase(updateGarageSaleAction.pending, (state) => {
                state.updateSellerStatus = AsyncRequestStatus.Loading;
            })
            .addCase(updateGarageSaleAction.fulfilled, (state, action) => {
                state.status = AsyncRequestStatus.Idle;
                state.updateSellerStatus = AsyncRequestStatus.Idle;
                state.garageSale = action.payload.garageSale;
            })
            .addCase(updateGarageSaleAction.rejected, (state) => {
                state.status = AsyncRequestStatus.Failed;
            })
            .addCase(confirmSellerAction.pending, (state) => {
                // state.status = AsyncRequestStatus.Loading;
            })
            .addCase(confirmSellerAction.fulfilled, (state, action) => {
                state.status = AsyncRequestStatus.Refresh;
            })
            .addCase(confirmSellerAction.rejected, (state) => {
                state.status = AsyncRequestStatus.Failed;
            })
            .addCase(deleteSellerAction.pending, (state) => {
                // state.status = AsyncRequestStatus.Loading;
            })
            .addCase(deleteSellerAction.fulfilled, (state, action) => {
                state.status = AsyncRequestStatus.Refresh;
            })
            .addCase(deleteSellerAction.rejected, (state) => {
                state.status = AsyncRequestStatus.Failed;
            })
            .addCase(updateSellerAction.pending, (state) => {
                state.updateSellerStatus = AsyncRequestStatus.Loading;
            })
            .addCase(updateSellerAction.fulfilled, (state, action) => {
                state.status = AsyncRequestStatus.Refresh;
                state.updateSellerStatus = AsyncRequestStatus.Idle;
                state.selectedSeller = null;
            })
            .addCase(updateSellerAction.rejected, (state) => {
                state.updateSellerStatus = AsyncRequestStatus.Failed;
            });
    }
});

// export const { resetSelectedSeller, setSelectedSeller, tmpSetSellerLoading, tmpSetSellerIdle } = garageSaleSlice.actions;
export const { resetSelectedSeller, setSelectedSeller } = garageSaleSlice.actions;

export const selectGarageSale = (state: RootState) => state.garagesale;

export default garageSaleSlice.reducer;