import {ActionIcon, Group, Tooltip, Text, useMantineTheme, TextInput} from "@mantine/core";
import { modals } from '@mantine/modals';
import React from "react";
import {ColumnProps} from "./ColumnProps";
import {IconEdit, IconThumbDown, IconThumbUp, IconTrash} from "@tabler/icons-react";
import GarageSaleSeller from "../../../../features/garagesale/models/GarageSaleSeller";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {
    confirmSellerAction, deleteSellerAction,
    selectGarageSale,
    setSelectedSeller
} from "../../../../features/garagesale/garagesaleSlice";
import {ConfirmSellerRequest, DeleteSellerRequest} from "../../../../features/garagesale/garagesaleAPI";



const ActionsColumn = ({seller}: ColumnProps) => {
    const theme = useMantineTheme();
    const garageSale = useAppSelector(selectGarageSale);
    const dispatch = useAppDispatch();

    const overlayProps = {
        color: theme.colors.gray[2],
        opacity: 0.55,
        blur: 3,
    }

    const openApproveModal = (seller: GarageSaleSeller) => {
        modals.openConfirmModal({
            title: `Deelnemer ${seller.firstName} ${seller.lastName} bevestigen`,
            centered: true,
            children: (
                <Text size="sm">
                    Weet je zeker dat je deze deelnemer wil bevestigen?
                </Text>
            ),
            labels: {confirm: 'Bevestigen', cancel: "Annuleren"},
            confirmProps: {color: 'green'},
            onConfirm: () => {
                let request = {
                    slug: garageSale.garageSale?.slug,
                    id: seller.id
                } as ConfirmSellerRequest;
                dispatch(confirmSellerAction(request));
                }
            ,
            overlayProps: overlayProps
        });
    }

    // const openRejectModal = (seller: GarageSaleSeller) => {
    //     modals.openConfirmModal({
    //         title: `Deelnemer ${seller.firstName} ${seller.lastName} afwijzen`,
    //         centered: true,
    //         children: (
    //             <>
    //                 <Text size="sm">
    //                     Laat deze deelnemer weten waarom hij niet mee kan doen, bijvoorbeeld omdat hij buiten het gebied woont.
    //                 </Text>
    //                 <TextInput label="Reden" placeholder="Waarom wordt deze deelnemer afgewezen?" data-autofocus />
    //             </>
    //         ),
    //         labels: { confirm: 'Afwijzen', cancel: 'Annuleren' },
    //         confirmProps: {color: 'red'},
    //         overlayProps: overlayProps
    //     });
    // }

    const openEditModal = (seller: GarageSaleSeller) => {
        dispatch(setSelectedSeller(seller))
    }
    
    const openDeleteModal = (seller: GarageSaleSeller) => {
        modals.openConfirmModal({
            title: `Deelnemer ${seller.firstName} ${seller.lastName} verwijderen`,
            centered: true,
            children: (
                <Text size="sm">
                    Weet je zeker dat je deze deelnemer definitief wil verwijderen?<br/>
                    <em>Let op: dit kan niet ongedaan gemaakt worden.</em>
                </Text>
            ),
            labels: {confirm: 'Verwijderen', cancel: "Annuleren"},
            confirmProps: {color: 'red'},
            onConfirm: () => {
                let request = {
                    slug: garageSale.garageSale?.slug,
                    id: seller.id
                } as DeleteSellerRequest;
                dispatch(deleteSellerAction(request));
            }
            ,
            overlayProps: overlayProps
        });
    }
    
    return (
        <Group spacing={8} position="right" noWrap>
            <Tooltip label="Bevestigen">
                <ActionIcon disabled={seller.status === 2} variant="filled" color="green" onClick={() => openApproveModal(seller)}>
                    <IconThumbUp size={16}/>
                </ActionIcon>
            </Tooltip>
            {/*<Tooltip label="Afwijzen">*/}
            {/*    <ActionIcon variant="outline" color="red" onClick={() => openRejectModal(seller)}>*/}
            {/*        <IconThumbDown size={16}/>*/}
            {/*    </ActionIcon>*/}
            {/*</Tooltip>*/}
            <Tooltip label="Bewerken">
                <ActionIcon variant="outline" color="blue" onClick={() => openEditModal(seller)}>
                    <IconEdit size={16}/>
                </ActionIcon>
            </Tooltip>
            <Tooltip label="Verwijderen">
                <ActionIcon variant="outline" color="red" onClick={() => openDeleteModal(seller)}>
                    <IconTrash size={16}/>
                </ActionIcon>
            </Tooltip>
        </Group>
    );
};

export default ActionsColumn;