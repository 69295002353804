import React, {PropsWithChildren, useEffect} from "react";
import {IoMapSharp, IoPersonCircleOutline} from "react-icons/io5";
import {Link, useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectAuth, logout, resetRedirect} from "../../features/auth/authSlice";
import {AsyncRequestStatus} from "../../enums/AsyncRequestStatus";

type LayoutProps = {
    overrideAuthStatus?: boolean
}

const Layout = (props: PropsWithChildren<LayoutProps>) => {
    const auth = useAppSelector(selectAuth);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (!auth.token && !props.overrideAuthStatus) {
            navigate('/login')
        }
        if (auth.status === AsyncRequestStatus.Redirect) {
            navigate('/garagesale/aalsmeer');
            dispatch(resetRedirect())
        }
    }, [auth, dispatch, navigate, props]);

    const doLogout = () => {
        dispatch(logout());
    }
    
    return (
        <>
            <header className='top'>
                <div className="container">
                    <div className="top-container">
                        <Link to="/">
                            <div className="logo">
                                <span className="logo-icon"><IoMapSharp /></span>
                                <h1>BuurtRoute</h1>
                            </div>
                        </Link>
                        {/*<ul className="menu menu-hide">*/}
                        {/*    <li><a href="#">Routes</a></li>*/}
                        {/*    <li><a href="#">FAQ</a></li>*/}
                        {/*    <li><a href="#">Contact</a></li>*/}
                        {/*</ul>*/}
                        <ul className="menu">
                            {auth.token && <li>{auth.firstName} <span style={{cursor: 'pointer', fontWeight: 100, marginLeft: '0.75em'}} onClick={doLogout}>(uitloggen)</span></li>}
                            {!auth.token && <li><a href="/login"><span className="menu-icon"><IoPersonCircleOutline /></span></a></li>}
                        </ul>
                    </div>
                </div>
            </header>

            <div className="layout-container">
                <div className="container">
                    {auth.token && props.children}
                    {!auth.token && props.overrideAuthStatus && props.children}
                </div>
            </div>

            <div className="container">
                <div className="footer">
                    <div>© 2023 BuurtRoute</div>
                    <ul>
                        <li>Privacy Policy</li>
                        <li>Algemene voorwaarden</li>
                    </ul>
                </div>
            </div>
        </>
    );
}

export default Layout;